.serviceCard {
  background: #007991;
  background: -webkit-linear-gradient(to bottom, #ffb672, #8f02bb);
  background: linear-gradient(to bottom, #ffb672, #8f02bb);
  width: 300px;
  height: 210px;
  border-radius: 0 0 0.5em 0.5em;
  border-top: 4px solid #202020;
  box-shadow: 0 20px 20px -15px #8f02bb;
  margin: 1em;
  /* padding: 0.5em; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.serviceCard__image {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.serviceCard__image img {
  max-width: 220px;
  transition: 0.2s ease-out;
  -webkit-filter: drop-shadow(0 4px 5px #3b3b3b);
  filter: drop-shadow(0 4px 5px #3b3b3b);
}

.serviceCard__tittle {
  text-align: center;
  height: 3em;
}

.serviceCard__tittle h5 {
  font-weight: 700;
  color: #ffffff;
  margin-top: 0.5em;
}

.serviceCard__btn h4 {
  font-weight: 700;
  color: #ffffff;
  text-shadow: 0 0 10px #202020;
  margin-top: 0.5em;
  text-transform: capitalize;
}

.serviceCard__btn {
  display: none;
  text-align: center;
  margin-top: -3em;
  height: 100%;
  width: 100%;
  border-radius: 0 0 0.5em 0.5em;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #9002bb44;
  transition: 0.5s ease-out;
  position: absolute;
  bottom: 0;
}

.serviceCard__btn span {
  font-size: 2em;
  color: #fff;
}

.serviceCard:hover .serviceCard__btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* justify-content: space-around; */
  /* align-items: center; */
  transition: 0.5s ease-out;
  cursor: pointer;
}

.serviceCard:hover {
  box-shadow: 0 10px 20px -5px #8f02bb;
}

.serviceCard:hover .serviceCard__image img {
  max-width: 240px;
}

@media (max-width: 648px) {
  .serviceCard {
    width: 90%;
    margin: 1em 0;
  }
}
