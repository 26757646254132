@import url(https://fonts.googleapis.com/css2?family=Nanum+Myeongjo);
.courseCard {
  min-height: 90vh;
}

.courseCard__container {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
}

.courseCard__image img {
  width: 50vw;
}

.courseCard__course {
  background-image: linear-gradient(90deg, #ffb672 0%, #8f02bb 100%);
  border-radius: 0.4em;
  margin: 0.8em;
  width: 95%;
  padding: 0.4em;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.courseCard__course:hover {
  background-image: none;
  background-color: #8f02bb;
}

.courseCard__list {
  width: 80%;
  margin: 2em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.courseCard__list a {
  color: #fff;
  width: 100%;
}

.courseCard__list a:hover {
  color: #fff;
}

.courseCard__list p {
  width: 80%;
  text-transform: capitalize;
  font-size: 1.5em;
  font-weight: 800;
  color: #383838;
}

.courseCard__btn {
  background-color: transparent;
  border: none;
  margin: 1em;
  display: flex;
  justify-content: space-between;
  width: 5.5em;
  font-size: 1.5em;
  font-weight: 800;
  color: #8f02bb;
}

.courseCard__btn:focus {
  outline: none;
}

.courseCard__course span {
  color: #383838;
  padding: 0 0.3em;
  font-size: 1.7em;
}

.courseCard__list h4 {
  /* border-bottom: 1px solid #7d7d7d; */
  width: 100%;
  padding-top: 0.3em;
  /* padding: 0.3em 0 0.3em 0;
  margin-bottom: 1.2em; */
  font-weight: 700;
  text-transform: capitalize;
}

@media (max-width: 648px) {
  .courseCard__container {
    flex-direction: column;
  }

  .courseCard__list p {
    width: 100%;
    padding: 0.4em;
    font-size: 1.2em;
  }

  .courseCard__image img {
    /* margin-top: -4em; */
    width: 90vw;
  }

  .courseCard__list h4 {
    font-size: 1em;
  }

  .courseCard__list {
    width: 95%;
    margin: 1em;
  }

  .courseCard__course span {
    margin-top: -0.3em;
  }
}

.hero {
  color: #202020;
  width: 100vw;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
}

.hero__headings {
  margin-top: -5em;
  padding-left: 3em;
  padding-right: 3em;
  max-width: 580px;
}

.hero__headings h1 {
  margin-right: -5em;
  font-size: 4em;
}

.hero__headings h6 {
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 1.2em;
  color: #676767;
}

.hero__headings h1,
.hero__headings h3,
.hero__headings h6 {
  font-weight: 700;
}

.hero__btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.8em 1.1em;
  border: none;
  border-radius: 0.4em;
  text-transform: capitalize;
  font-size: 1.2em;
  background-color: #8f02bb;
  box-shadow: 0 5px 10px -3px #8f02bb;
  color: #ffffff;
}

.hero__btn:hover {
  box-shadow: none;
  background-color: #630d7d;
}

.hero__btn:focus {
  outline: none;
}

.hero__image {
  width: 60vw;
}

.hero__image img {
  width: 55vw;
  object-fit: cover;
  -webkit-filter: drop-shadow(0 1px 5px #3b3b3b);
  filter: drop-shadow(0 1px 5px #63636371);
}

@media (max-width: 648px) {
  .hero {
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }

  .hero__headings {
    margin-top: 0;
    width: 100vw;
    padding-left: 1em;
  }

  .hero__headings h1 {
    margin-right: 0.5em;
    font-size: 3em;
  }

  .hero__headings h6 {
    margin-top: auto;
    font-size: 1em;
  }

  .hero__btn {
    margin-top: 0em;
    padding: 0.4em 0.8em;
  }

  .hero__image img {
    width: 100vw;
    margin-top: -3em;
  }
}

.navBar__container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.navBar__container marquee {
  background-color: #8f02bb;
  color: rgb(255, 255, 255);
  font-size: 1.3em;
  padding: 0.1em;
}

.navBar {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /* background: rgb(160, 255, 214); */
  color: #202020;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5em;
  box-shadow: 0 5px 10px -10px #202020;
  /* margin-top: -0.5em; */
}

.navBar__marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 1.7em;
  overflow-x: hidden;
  background-color: #8f02bb;
  color: rgb(255, 255, 255);
  font-size: 1.1em;
  padding: 0.1em;
}

.navBar__track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(-115%);
  }
}

.navBar__logo {
  display: flex;
  align-items: center;
  margin-left: 1em;
}

.navBar__logoText {
  padding-left: 0.6em;
  padding-top: 15px;
}

.navBar__logo img {
  width: 4em;
}

.navBar__logo h3 {
  font-weight: 800;
  margin-bottom: -2px;
  text-shadow: 0 1px 2px rgba(91, 91, 91, 0.394);
}

.navBar__nav {
  height: 1.5em;
  padding-right: 1.5em;
}

.navBar__nav ul li a {
  color: #202020;
  text-transform: uppercase;
  font-weight: 700;
}

.navBar__nav ul li {
  display: inline;
  list-style: none;
  padding: 0.5em;
  width: 25em;
}

.navBar__menuIcon {
  display: none;
}

@media (max-width: 648px) {
  .navBar__logo img {
    width: 3em;
  }

  .navBar__logo h3 {
    font-size: 1.4em;
  }

  .navBar__logoText p {
    font-size: 0.9em;
  }

  .navBar__nav ul li {
    display: block;
    padding: 1em 0;
    margin: auto;
    width: 100%;
    border-bottom: 0.5px solid rgb(154, 154, 154);
  }

  .navBar__menuIcon {
    display: block;
    font-size: 3em;
    padding: 0;
    margin: 0;
    margin-top: -0.2em;
    text-align: center;
    width: 1em;
    transition: all 0.5 ease;
  }

  .hideNav {
    display: none;
  }

  .showNav {
    display: block;
    position: absolute;
    top: 5em;
    left: 0;
    text-align: left;
    width: 100%;
    height: 100vh;
    background-color: rgb(50, 50, 50);
    z-index: 99;
  }

  .navBar__nav ul li a {
    color: rgb(160, 255, 214);
    text-transform: uppercase;
    font-weight: 400;
    font-size: 2.5em;
  }
}

.serviceCard {
  background: #007991;
  background: linear-gradient(to bottom, #ffb672, #8f02bb);
  width: 300px;
  height: 210px;
  border-radius: 0 0 0.5em 0.5em;
  border-top: 4px solid #202020;
  box-shadow: 0 20px 20px -15px #8f02bb;
  margin: 1em;
  /* padding: 0.5em; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.serviceCard__image {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.serviceCard__image img {
  max-width: 220px;
  transition: 0.2s ease-out;
  -webkit-filter: drop-shadow(0 4px 5px #3b3b3b);
  filter: drop-shadow(0 4px 5px #3b3b3b);
}

.serviceCard__tittle {
  text-align: center;
  height: 3em;
}

.serviceCard__tittle h5 {
  font-weight: 700;
  color: #ffffff;
  margin-top: 0.5em;
}

.serviceCard__btn h4 {
  font-weight: 700;
  color: #ffffff;
  text-shadow: 0 0 10px #202020;
  margin-top: 0.5em;
  text-transform: capitalize;
}

.serviceCard__btn {
  display: none;
  text-align: center;
  margin-top: -3em;
  height: 100%;
  width: 100%;
  border-radius: 0 0 0.5em 0.5em;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #9002bb44;
  transition: 0.5s ease-out;
  position: absolute;
  bottom: 0;
}

.serviceCard__btn span {
  font-size: 2em;
  color: #fff;
}

.serviceCard:hover .serviceCard__btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* justify-content: space-around; */
  /* align-items: center; */
  transition: 0.5s ease-out;
  cursor: pointer;
}

.serviceCard:hover {
  box-shadow: 0 10px 20px -5px #8f02bb;
}

.serviceCard:hover .serviceCard__image img {
  max-width: 240px;
}

@media (max-width: 648px) {
  .serviceCard {
    width: 90%;
    margin: 1em 0;
  }
}

.services {
  min-height: 90vh;
}

.services__cards {
  max-width: 80vw;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-around;
}

@media (max-width: 648px) {
  .services__cards {
    max-width: 100vw;
  }
}

.therapies {
  min-height: 95vh;
}

.therapies__container {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
}

.therapies__image img {
  width: 45vw;
}

.therapies__therapy {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #007991;
  background: linear-gradient(to right, #ffb672, #8f02bb);
  border-radius: 0 2.5em 2.5em 0;
  border-left: 5px solid #202020;
  margin: 1em;
  width: 100%;
  position: relative;
}

.therapies__title {
  color: #202020;
}

.therapies__list {
  padding: 0 5em;
  width: 45%;
}

.therapies__therapy span {
  color: #383838;
  padding: 0 0.5em;
  font-size: 1.7em;
  margin-top: -0.5em;
}

.therapies__list h4 {
  color: #fff;
  width: 100%;
  padding: 0.8em 0;
  font-weight: 700;
  text-transform: capitalize;
}

.therapies__btn {
  display: none;
  display: flex;
  text-align: center;
  margin-top: -3em;
  height: 100%;
  width: 100%;
  border-radius: 0 2.5em 2.5em 0;
  background-color: #8f02bb;
  color: #fff;
  transition: 0.5s ease-out;
  position: absolute;
  bottom: 0;
  left: -50em;
  opacity: 0;
}

.therapies__btn span {
  font-size: 3em;
  color: #fff;
  padding-right: 2em;
  padding-top: 0.4em;
}

.therapies__therapy:hover .therapies__btn {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  left: 0;
  opacity: 1;
  transition: 0.3s ease;
  cursor: pointer;
}

@media (max-width: 648px) {
  .therapies__container {
    flex-direction: column;
  }

  .therapies__image img {
    width: 90vw;
  }

  .therapies__list h4 {
    font-size: 1em;
  }

  .therapies__list {
    width: 95%;
    padding: 1em;
  }

  .therapies__btn span {
    font-size: 2em;
    padding-right: 1em;
    padding-top: 0.2em;
  }

  .therapies__therapy {
    margin: 0.5em 0;
  }

  .therapies__therapy span {
    margin-top: -0.3em;
  }
}

.course {
  /* background: #8e2de2;
  background: -webkit-linear-gradient(to bottom, #702ef5, #8e2de2);
  background: linear-gradient(to bottom, #702ef5, #8e2de2); */

  /* background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%); */

  background-image: linear-gradient(110deg, #ffdee9 0%, #b5fffc 100%);

  /* background-color: #fff; */
  color: #202020;
  margin: 2em;
  padding: 1em 1.5em 8em 1.5em;
  box-shadow: 0 20px 30px -15px #383838c2;
  width: 40em;
  font-size: 0.8em;
  border-radius: 1em;
  cursor: pointer;
  position: relative;
}

.course:hover {
  box-shadow: 0 0 30px -10px #38383884;
}

.course h1 {
  font-size: 2em;
  font-weight: 600;
  margin: 0.6em;
  margin-bottom: 2em;
  text-align: center;
  text-transform: capitalize;
  text-shadow: 0 1px 2px #5656566d;
}

.course__details {
  border-bottom: 1px solid #4e4e4e9c;
  color: #393939;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 4px;
  font-size: 1.5em;
}

.course__details img {
  width: 1em;
  margin: -0.5em 1em 0 1em;
}

.course__details p {
  margin-right: 0.5em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.largeIcon img {
  width: 1.3em;
}

.smallIcon img {
  width: 0.7em;
  margin-right: 1.3em;
}

.course__title {
  display: flex;
}

.course__title p {
  width: 5.8em;
}

.course__actionBtn {
  position: absolute;
  bottom: 0.5em;
  left: 5%;
  background-color: #8f02bb;
  width: 90%;
  border: none;
  outline: none;
  text-align: center;
  color: #fff;
  border-radius: 1em;
  font-size: 1.6em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 0.5em;
  margin: 1em auto;
  box-shadow: 0 5px 15px -3px #9002bb8b;
}

.course__actionBtn:hover {
  background-color: #64127d;
  color: #eee;
  box-shadow: 0 2px 5px #510469;
}

.course__actionBtn:focus {
  outline: none;
}

@media (max-width: 648px) {
  .course {
    width: 100%;
    margin: 2em 1em;
  }
  .course__details {
    align-items: flex-start;
  }

  .course__details p {
    margin-right: 0.2em;
    font-size: 0.8em;
  }

  .course__details img {
    margin: -0.5em 0.5em 0 0;
  }

  .largeIcon img {
    width: 1em;
  }

  .smallIcon img {
    width: 0.6em;
    margin-right: 1.1em;
  }

  .course h1 {
    font-size: 1.5em;
  }
}

.coursesPage__courses {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.coursesPage h4 {
  width: 100%;
  font-size: 1.5em;
  text-align: center;
  padding: 0.5em;
  font-weight: 800;
  color: rgb(99, 99, 99);
}

.about__aboutContainer {
  position: relative;
  min-width: 100%;
  height: 100vh;
  display: flex;
  margin-bottom: 3em;
}

.about__aboutTitle {
  background: linear-gradient(rgb(32, 117, 80), rgb(160, 255, 214));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  left: 50%;
  top: 4em;
  transform: translate(-50%);
  z-index: -1;
  opacity: 0.4;
}

.about__aboutTitle h1 {
  font-size: 16em;
  line-height: 0.8em;
  text-transform: uppercase;
  font-family: Impact, fantasy, sans-serif;
}

.about__aboutText {
  width: 50em;
  padding: 0 1em;
  margin: 0 auto;
  margin-top: 14em;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  padding: 0.5em 0.3em;
  border-radius: 0.6em;
}

.about__aboutText h5 {
  font-weight: 800;
  font-size: 2.1em;
  color: #565656;
  text-transform: capitalize;
  text-align: justify;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.299);
}

.about__mission {
  width: 100%;
  align-items: center;
}

.about__missionHeading {
  margin: 4em auto;
}

.about__missionContainer {
  display: flex;
  width: 60%;
  height: 10em;
  margin: 1em auto;
  margin-bottom: 5em;
  /* justify-content: center; */
  align-items: center;
}

.about__missionTitle {
  background: linear-gradient(rgb(32, 117, 80), rgb(160, 255, 214));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translate(-50%);
  z-index: -1;
  opacity: 0.3;
}

.about__missionTitle h1 {
  font-size: 16em;
  line-height: 0.8em;
  text-transform: uppercase;
  font-family: Impact, fantasy, sans-serif;
}

.about__missionText {
  margin-left: -8em;
  width: 100%;
}

.about__missionText h1 {
  font-weight: 800;
  color: #565656;
  text-transform: capitalize;
  text-align: left;
  width: 100%;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.299);
}

@media (max-width: 648px) {
  .about__aboutTitle h1 {
    font-size: 7em;
  }

  .about__aboutTitle {
    top: 2em;
  }

  .about__aboutText {
    margin-top: 6.5em;
  }

  .about__aboutText h5 {
    font-size: 1.4em;
    text-align: center;
    letter-spacing: 1px;
    word-spacing: 3px;
    line-height: 1.5em;
    width: 95%;
    margin: auto;
  }

  .about__missionContainer {
    width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .about__missionTitle {
    transform: none;
  }

  .about__missionText {
    margin-left: -4em;
  }

  .about__missionText h1 {
    font-size: 1.3em;
  }
}

.contact {
  background-color: rgb(160, 255, 214);
  color: #202020;
  width: 100vw;
  height: 92vh;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.contact__formContainer {
  width: 50%;
}

.contact__details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin-bottom: 1em;
}

.contact__data {
  display: flex;
  /* justify-content: center; */
  align-items: flex-start;
}

.contact__data p {
  font-weight: 800;
}

.contact__data a {
  color: #202020;
  font-size: 1.1em;
  letter-spacing: 0.5px;
}

.contact__data img {
  width: 2em;
  margin-right: 1em;
}

.contact__form {
  display: flex;
  flex-direction: column;

  width: 25em;
  margin: 2em auto;
}

.contact__form h1 {
  font-size: 3em;
  text-align: center;
  font-weight: 700;
  width: 100%;
  margin-bottom: 1em;
}

.contact__form input,
.contact__form textarea {
  background-color: #eee;
  border: none;
  width: 100%;
  border-radius: 0.4em;
  font-size: 1.5em;
  margin-bottom: 0.6em;
  padding: 0.2em;
  box-shadow: 0 10px 20px rgba(25, 157, 93, 0.483);
}

.contact__form input:focus,
.contact__form textarea:focus {
  outline: none;
  box-shadow: 0 0 8px rgb(25, 157, 93);
}

.contact__btn {
  width: 100%;
  padding: 0.6em 1.1em;
  border: none;
  border-radius: 1em;
  font-size: 1.2em;
  background-color: #8f02bb;
  box-shadow: 0 5px 10px -3px #8f02bb;
  color: #ffffff;
  margin-top: 1em;
}

.contact__btn:focus {
  outline: none;
}

.contact__image {
  transform: scale(-1, 1);
  width: 80vw;
  height: 70vh;
  position: absolute;
  bottom: -72vh;
  right: -18vw;
  display: flex;
  justify-content: baseline;
  align-items: flex-end;
}

.contact__image img {
  width: 100%;
  object-fit: cover;
  -webkit-filter: drop-shadow(0 4px 8px #3b3b3b);
  filter: drop-shadow(0 4px 8px #636363a3);
}

@media (max-width: 648px) {
  .contact {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .contact__data img {
    width: 1.5em;
    margin-right: 0.5em;
  }

  .contact__data a {
    font-size: 1em;
  }

  .contact__formContainer {
    width: 100%;
  }
  .contact__form {
    margin-top: 0;
    width: 90%;
  }

  .contact__image {
    display: none;
  }
}

.footer {
  height: 50vh;
  width: 100%;
  background-color: #202020;
  color: #fff;
  position: relative;
  overflow-x: hidden;
}

.footer__container {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: space-evenly;
  padding-top: 2em;
}

.footer__leftSide {
  width: 25em;
  height: 100%;
}

.footer__leftSide span {
  font-size: 1.5em;
  margin-right: 0.5em;
}

.footer__description {
  color: #acacac;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  letter-spacing: 0.2px;
  font-size: 1.1em;
}

.footer__leftSide a {
  color: #fff;
  /* text-transform: capitalize; */
  letter-spacing: 1px;
}

.footer__social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3em;
}

.footer__social h2 {
  margin-right: 1em;
}

.footer__iconContainer {
  display: flex;
}

.footer__icon {
  display: flex;
  align-items: center;
  align-items: center;
  font-size: 1.5em;
  padding: 0.4em;
  margin: auto 0.5em;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50%;
}

.footer__icon:hover {
  color: #202020;
  background-color: #fff;
  cursor: pointer;
}

.footer__rightSide {
  width: 25em;
  height: 100%;
}

.footer__copyRight {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 0.8em;
  padding: 0 0.2em;
}

.footer__copyRight p {
  text-align: center;
  color: #acacac;
}

.footer__copyRight a {
  color: #d5d5d5;
  font-weight: 800;
}

@media (max-width: 648px) {
  .footer {
    height: 90vh;
    padding: 0.5em;
    margin-top: 2em;
  }

  .footer__container {
    flex-direction: column;
  }

  .footer__social {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2em;
  }
}

* {
  margin: 0;
}

body {
  color: #202020;
  background-color: rgb(160, 255, 214);
  font-family: "Nanum Myeongjo", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  overflow-x: hidden;
}
body {
  height: 100%;
}

html,
body {
  width: 100vw;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}

.heading {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  margin: 1em auto;
  font-weight: 800;
  text-transform: capitalize;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.279);
  background: linear-gradient(
    180deg,
    rgb(160, 255, 214) 60%,
    rgb(78, 226, 162) 40%
  );
}

.noLink:hover {
  color: #fff;
  text-decoration: none;
}

.location {
  background-color: rgb(5, 5, 5);
  color: aliceblue;
  border-radius: 0.8em;
  text-align: center;
  position: fixed;
  right: 1em;
  bottom: 1em;
  font-size: 1.5em;
  width: 3em;
  height: 1.1em;
  z-index: 999;
}

@media (max-width: 648px) {
  .heading {
    font-size: 1.8em;
  }
}

