@import url("https://fonts.googleapis.com/css2?family=Nanum+Myeongjo");

* {
  margin: 0;
}

body {
  color: #202020;
  background-color: rgb(160, 255, 214);
  font-family: "Nanum Myeongjo", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  overflow-x: hidden;
}
body {
  height: 100%;
}

html,
body {
  width: 100vw;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}

.heading {
  width: fit-content;
  text-align: center;
  margin: 1em auto;
  font-weight: 800;
  text-transform: capitalize;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.279);
  background: linear-gradient(
    180deg,
    rgb(160, 255, 214) 60%,
    rgb(78, 226, 162) 40%
  );
}

.noLink:hover {
  color: #fff;
  text-decoration: none;
}

.location {
  background-color: rgb(5, 5, 5);
  color: aliceblue;
  border-radius: 0.8em;
  text-align: center;
  position: fixed;
  right: 1em;
  bottom: 1em;
  font-size: 1.5em;
  width: 3em;
  height: 1.1em;
  z-index: 999;
}

@media (max-width: 648px) {
  .heading {
    font-size: 1.8em;
  }
}
