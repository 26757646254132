.navBar__container {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.navBar__container marquee {
  background-color: #8f02bb;
  color: rgb(255, 255, 255);
  font-size: 1.3em;
  padding: 0.1em;
}

.navBar {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /* background: rgb(160, 255, 214); */
  color: #202020;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5em;
  box-shadow: 0 5px 10px -10px #202020;
  /* margin-top: -0.5em; */
}

.navBar__marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 1.7em;
  overflow-x: hidden;
  background-color: #8f02bb;
  color: rgb(255, 255, 255);
  font-size: 1.1em;
  padding: 0.1em;
}

.navBar__track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(-115%);
  }
}

.navBar__logo {
  display: flex;
  align-items: center;
  margin-left: 1em;
}

.navBar__logoText {
  padding-left: 0.6em;
  padding-top: 15px;
}

.navBar__logo img {
  width: 4em;
}

.navBar__logo h3 {
  font-weight: 800;
  margin-bottom: -2px;
  text-shadow: 0 1px 2px rgba(91, 91, 91, 0.394);
}

.navBar__nav {
  height: 1.5em;
  padding-right: 1.5em;
}

.navBar__nav ul li a {
  color: #202020;
  text-transform: uppercase;
  font-weight: 700;
}

.navBar__nav ul li {
  display: inline;
  list-style: none;
  padding: 0.5em;
  width: 25em;
}

.navBar__menuIcon {
  display: none;
}

@media (max-width: 648px) {
  .navBar__logo img {
    width: 3em;
  }

  .navBar__logo h3 {
    font-size: 1.4em;
  }

  .navBar__logoText p {
    font-size: 0.9em;
  }

  .navBar__nav ul li {
    display: block;
    padding: 1em 0;
    margin: auto;
    width: 100%;
    border-bottom: 0.5px solid rgb(154, 154, 154);
  }

  .navBar__menuIcon {
    display: block;
    font-size: 3em;
    padding: 0;
    margin: 0;
    margin-top: -0.2em;
    text-align: center;
    width: 1em;
    transition: all 0.5 ease;
  }

  .hideNav {
    display: none;
  }

  .showNav {
    display: block;
    position: absolute;
    top: 5em;
    left: 0;
    text-align: left;
    width: 100%;
    height: 100vh;
    background-color: rgb(50, 50, 50);
    z-index: 99;
  }

  .navBar__nav ul li a {
    color: rgb(160, 255, 214);
    text-transform: uppercase;
    font-weight: 400;
    font-size: 2.5em;
  }
}
