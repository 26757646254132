.hero {
  color: #202020;
  width: 100vw;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
}

.hero__headings {
  margin-top: -5em;
  padding-left: 3em;
  padding-right: 3em;
  max-width: 580px;
}

.hero__headings h1 {
  margin-right: -5em;
  font-size: 4em;
}

.hero__headings h6 {
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 1.2em;
  color: #676767;
}

.hero__headings h1,
.hero__headings h3,
.hero__headings h6 {
  font-weight: 700;
}

.hero__btn {
  width: fit-content;
  padding: 0.8em 1.1em;
  border: none;
  border-radius: 0.4em;
  text-transform: capitalize;
  font-size: 1.2em;
  background-color: #8f02bb;
  box-shadow: 0 5px 10px -3px #8f02bb;
  color: #ffffff;
}

.hero__btn:hover {
  box-shadow: none;
  background-color: #630d7d;
}

.hero__btn:focus {
  outline: none;
}

.hero__image {
  width: 60vw;
}

.hero__image img {
  width: 55vw;
  object-fit: cover;
  -webkit-filter: drop-shadow(0 1px 5px #3b3b3b);
  filter: drop-shadow(0 1px 5px #63636371);
}

@media (max-width: 648px) {
  .hero {
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }

  .hero__headings {
    margin-top: 0;
    width: 100vw;
    padding-left: 1em;
  }

  .hero__headings h1 {
    margin-right: 0.5em;
    font-size: 3em;
  }

  .hero__headings h6 {
    margin-top: auto;
    font-size: 1em;
  }

  .hero__btn {
    margin-top: 0em;
    padding: 0.4em 0.8em;
  }

  .hero__image img {
    width: 100vw;
    margin-top: -3em;
  }
}
