.therapies {
  min-height: 95vh;
}

.therapies__container {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
}

.therapies__image img {
  width: 45vw;
}

.therapies__therapy {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #007991;
  background: -webkit-linear-gradient(to right, #ffb672, #8f02bb);
  background: linear-gradient(to right, #ffb672, #8f02bb);
  border-radius: 0 2.5em 2.5em 0;
  border-left: 5px solid #202020;
  margin: 1em;
  width: 100%;
  position: relative;
}

.therapies__title {
  color: #202020;
}

.therapies__list {
  padding: 0 5em;
  width: 45%;
}

.therapies__therapy span {
  color: #383838;
  padding: 0 0.5em;
  font-size: 1.7em;
  margin-top: -0.5em;
}

.therapies__list h4 {
  color: #fff;
  width: 100%;
  padding: 0.8em 0;
  font-weight: 700;
  text-transform: capitalize;
}

.therapies__btn {
  display: none;
  display: flex;
  text-align: center;
  margin-top: -3em;
  height: 100%;
  width: 100%;
  border-radius: 0 2.5em 2.5em 0;
  background-color: #8f02bb;
  color: #fff;
  transition: 0.5s ease-out;
  position: absolute;
  bottom: 0;
  left: -50em;
  opacity: 0;
}

.therapies__btn span {
  font-size: 3em;
  color: #fff;
  padding-right: 2em;
  padding-top: 0.4em;
}

.therapies__therapy:hover .therapies__btn {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  left: 0;
  opacity: 1;
  transition: 0.3s ease;
  cursor: pointer;
}

@media (max-width: 648px) {
  .therapies__container {
    flex-direction: column;
  }

  .therapies__image img {
    width: 90vw;
  }

  .therapies__list h4 {
    font-size: 1em;
  }

  .therapies__list {
    width: 95%;
    padding: 1em;
  }

  .therapies__btn span {
    font-size: 2em;
    padding-right: 1em;
    padding-top: 0.2em;
  }

  .therapies__therapy {
    margin: 0.5em 0;
  }

  .therapies__therapy span {
    margin-top: -0.3em;
  }
}
