.contact {
  background-color: rgb(160, 255, 214);
  color: #202020;
  width: 100vw;
  height: 92vh;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.contact__formContainer {
  width: 50%;
}

.contact__details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin-bottom: 1em;
}

.contact__data {
  display: flex;
  /* justify-content: center; */
  align-items: flex-start;
}

.contact__data p {
  font-weight: 800;
}

.contact__data a {
  color: #202020;
  font-size: 1.1em;
  letter-spacing: 0.5px;
}

.contact__data img {
  width: 2em;
  margin-right: 1em;
}

.contact__form {
  display: flex;
  flex-direction: column;

  width: 25em;
  margin: 2em auto;
}

.contact__form h1 {
  font-size: 3em;
  text-align: center;
  font-weight: 700;
  width: 100%;
  margin-bottom: 1em;
}

.contact__form input,
.contact__form textarea {
  background-color: #eee;
  border: none;
  width: 100%;
  border-radius: 0.4em;
  font-size: 1.5em;
  margin-bottom: 0.6em;
  padding: 0.2em;
  box-shadow: 0 10px 20px rgba(25, 157, 93, 0.483);
}

.contact__form input:focus,
.contact__form textarea:focus {
  outline: none;
  box-shadow: 0 0 8px rgb(25, 157, 93);
}

.contact__btn {
  width: 100%;
  padding: 0.6em 1.1em;
  border: none;
  border-radius: 1em;
  font-size: 1.2em;
  background-color: #8f02bb;
  box-shadow: 0 5px 10px -3px #8f02bb;
  color: #ffffff;
  margin-top: 1em;
}

.contact__btn:focus {
  outline: none;
}

.contact__image {
  transform: scale(-1, 1);
  width: 80vw;
  height: 70vh;
  position: absolute;
  bottom: -72vh;
  right: -18vw;
  display: flex;
  justify-content: baseline;
  align-items: flex-end;
}

.contact__image img {
  width: 100%;
  object-fit: cover;
  -webkit-filter: drop-shadow(0 4px 8px #3b3b3b);
  filter: drop-shadow(0 4px 8px #636363a3);
}

@media (max-width: 648px) {
  .contact {
    height: fit-content;
  }

  .contact__data img {
    width: 1.5em;
    margin-right: 0.5em;
  }

  .contact__data a {
    font-size: 1em;
  }

  .contact__formContainer {
    width: 100%;
  }
  .contact__form {
    margin-top: 0;
    width: 90%;
  }

  .contact__image {
    display: none;
  }
}
