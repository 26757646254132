.services {
  min-height: 90vh;
}

.services__cards {
  max-width: 80vw;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-around;
}

@media (max-width: 648px) {
  .services__cards {
    max-width: 100vw;
  }
}
