.about__aboutContainer {
  position: relative;
  min-width: 100%;
  height: 100vh;
  display: flex;
  margin-bottom: 3em;
}

.about__aboutTitle {
  background: -webkit-linear-gradient(rgb(32, 117, 80), rgb(160, 255, 214));
  background: linear-gradient(rgb(32, 117, 80), rgb(160, 255, 214));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  left: 50%;
  top: 4em;
  transform: translate(-50%);
  z-index: -1;
  opacity: 0.4;
}

.about__aboutTitle h1 {
  font-size: 16em;
  line-height: 0.8em;
  text-transform: uppercase;
  font-family: Impact, fantasy, sans-serif;
}

.about__aboutText {
  width: 50em;
  padding: 0 1em;
  margin: 0 auto;
  margin-top: 14em;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  padding: 0.5em 0.3em;
  border-radius: 0.6em;
}

.about__aboutText h5 {
  font-weight: 800;
  font-size: 2.1em;
  color: #565656;
  text-transform: capitalize;
  text-align: justify;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.299);
}

.about__mission {
  width: 100%;
  align-items: center;
}

.about__missionHeading {
  margin: 4em auto;
}

.about__missionContainer {
  display: flex;
  width: 60%;
  height: 10em;
  margin: 1em auto;
  margin-bottom: 5em;
  /* justify-content: center; */
  align-items: center;
}

.about__missionTitle {
  background: -webkit-linear-gradient(rgb(32, 117, 80), rgb(160, 255, 214));
  background: linear-gradient(rgb(32, 117, 80), rgb(160, 255, 214));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translate(-50%);
  z-index: -1;
  opacity: 0.3;
}

.about__missionTitle h1 {
  font-size: 16em;
  line-height: 0.8em;
  text-transform: uppercase;
  font-family: Impact, fantasy, sans-serif;
}

.about__missionText {
  margin-left: -8em;
  width: 100%;
}

.about__missionText h1 {
  font-weight: 800;
  color: #565656;
  text-transform: capitalize;
  text-align: left;
  width: 100%;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.299);
}

@media (max-width: 648px) {
  .about__aboutTitle h1 {
    font-size: 7em;
  }

  .about__aboutTitle {
    top: 2em;
  }

  .about__aboutText {
    margin-top: 6.5em;
  }

  .about__aboutText h5 {
    font-size: 1.4em;
    text-align: center;
    letter-spacing: 1px;
    word-spacing: 3px;
    line-height: 1.5em;
    width: 95%;
    margin: auto;
  }

  .about__missionContainer {
    width: 90%;
    height: fit-content;
  }

  .about__missionTitle {
    transform: none;
  }

  .about__missionText {
    margin-left: -4em;
  }

  .about__missionText h1 {
    font-size: 1.3em;
  }
}
