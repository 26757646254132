.courseCard {
  min-height: 90vh;
}

.courseCard__container {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
}

.courseCard__image img {
  width: 50vw;
}

.courseCard__course {
  background-image: linear-gradient(90deg, #ffb672 0%, #8f02bb 100%);
  border-radius: 0.4em;
  margin: 0.8em;
  width: 95%;
  padding: 0.4em;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.courseCard__course:hover {
  background-image: none;
  background-color: #8f02bb;
}

.courseCard__list {
  width: 80%;
  margin: 2em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.courseCard__list a {
  color: #fff;
  width: 100%;
}

.courseCard__list a:hover {
  color: #fff;
}

.courseCard__list p {
  width: 80%;
  text-transform: capitalize;
  font-size: 1.5em;
  font-weight: 800;
  color: #383838;
}

.courseCard__btn {
  background-color: transparent;
  border: none;
  margin: 1em;
  display: flex;
  justify-content: space-between;
  width: 5.5em;
  font-size: 1.5em;
  font-weight: 800;
  color: #8f02bb;
}

.courseCard__btn:focus {
  outline: none;
}

.courseCard__course span {
  color: #383838;
  padding: 0 0.3em;
  font-size: 1.7em;
}

.courseCard__list h4 {
  /* border-bottom: 1px solid #7d7d7d; */
  width: 100%;
  padding-top: 0.3em;
  /* padding: 0.3em 0 0.3em 0;
  margin-bottom: 1.2em; */
  font-weight: 700;
  text-transform: capitalize;
}

@media (max-width: 648px) {
  .courseCard__container {
    flex-direction: column;
  }

  .courseCard__list p {
    width: 100%;
    padding: 0.4em;
    font-size: 1.2em;
  }

  .courseCard__image img {
    /* margin-top: -4em; */
    width: 90vw;
  }

  .courseCard__list h4 {
    font-size: 1em;
  }

  .courseCard__list {
    width: 95%;
    margin: 1em;
  }

  .courseCard__course span {
    margin-top: -0.3em;
  }
}
