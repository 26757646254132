.course {
  /* background: #8e2de2;
  background: -webkit-linear-gradient(to bottom, #702ef5, #8e2de2);
  background: linear-gradient(to bottom, #702ef5, #8e2de2); */

  /* background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%); */

  background-image: linear-gradient(110deg, #ffdee9 0%, #b5fffc 100%);

  /* background-color: #fff; */
  color: #202020;
  margin: 2em;
  padding: 1em 1.5em 8em 1.5em;
  box-shadow: 0 20px 30px -15px #383838c2;
  width: 40em;
  font-size: 0.8em;
  border-radius: 1em;
  cursor: pointer;
  position: relative;
}

.course:hover {
  box-shadow: 0 0 30px -10px #38383884;
}

.course h1 {
  font-size: 2em;
  font-weight: 600;
  margin: 0.6em;
  margin-bottom: 2em;
  text-align: center;
  text-transform: capitalize;
  text-shadow: 0 1px 2px #5656566d;
}

.course__details {
  border-bottom: 1px solid #4e4e4e9c;
  color: #393939;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 4px;
  font-size: 1.5em;
}

.course__details img {
  width: 1em;
  margin: -0.5em 1em 0 1em;
}

.course__details p {
  margin-right: 0.5em;
  width: fit-content;
}

.largeIcon img {
  width: 1.3em;
}

.smallIcon img {
  width: 0.7em;
  margin-right: 1.3em;
}

.course__title {
  display: flex;
}

.course__title p {
  width: 5.8em;
}

.course__actionBtn {
  position: absolute;
  bottom: 0.5em;
  left: 5%;
  background-color: #8f02bb;
  width: 90%;
  border: none;
  outline: none;
  text-align: center;
  color: #fff;
  border-radius: 1em;
  font-size: 1.6em;
  text-transform: uppercase;
  font-weight: 800;
  padding: 0.5em;
  margin: 1em auto;
  box-shadow: 0 5px 15px -3px #9002bb8b;
}

.course__actionBtn:hover {
  background-color: #64127d;
  color: #eee;
  box-shadow: 0 2px 5px #510469;
}

.course__actionBtn:focus {
  outline: none;
}

@media (max-width: 648px) {
  .course {
    width: 100%;
    margin: 2em 1em;
  }
  .course__details {
    align-items: flex-start;
  }

  .course__details p {
    margin-right: 0.2em;
    font-size: 0.8em;
  }

  .course__details img {
    margin: -0.5em 0.5em 0 0;
  }

  .largeIcon img {
    width: 1em;
  }

  .smallIcon img {
    width: 0.6em;
    margin-right: 1.1em;
  }

  .course h1 {
    font-size: 1.5em;
  }
}
