.footer {
  height: 50vh;
  width: 100%;
  background-color: #202020;
  color: #fff;
  position: relative;
  overflow-x: hidden;
}

.footer__container {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: space-evenly;
  padding-top: 2em;
}

.footer__leftSide {
  width: 25em;
  height: 100%;
}

.footer__leftSide span {
  font-size: 1.5em;
  margin-right: 0.5em;
}

.footer__description {
  color: #acacac;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  letter-spacing: 0.2px;
  font-size: 1.1em;
}

.footer__leftSide a {
  color: #fff;
  /* text-transform: capitalize; */
  letter-spacing: 1px;
}

.footer__social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3em;
}

.footer__social h2 {
  margin-right: 1em;
}

.footer__iconContainer {
  display: flex;
}

.footer__icon {
  display: flex;
  align-items: center;
  align-items: center;
  font-size: 1.5em;
  padding: 0.4em;
  margin: auto 0.5em;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50%;
}

.footer__icon:hover {
  color: #202020;
  background-color: #fff;
  cursor: pointer;
}

.footer__rightSide {
  width: 25em;
  height: 100%;
}

.footer__copyRight {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 0.8em;
  padding: 0 0.2em;
}

.footer__copyRight p {
  text-align: center;
  color: #acacac;
}

.footer__copyRight a {
  color: #d5d5d5;
  font-weight: 800;
}

@media (max-width: 648px) {
  .footer {
    height: 90vh;
    padding: 0.5em;
    margin-top: 2em;
  }

  .footer__container {
    flex-direction: column;
  }

  .footer__social {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2em;
  }
}
