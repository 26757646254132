.coursesPage__courses {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.coursesPage h4 {
  width: 100%;
  font-size: 1.5em;
  text-align: center;
  padding: 0.5em;
  font-weight: 800;
  color: rgb(99, 99, 99);
}
